import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import logger from './utils/logger';
import getConfig from './config';

const config = getConfig();

function Auth0ProviderWithHistory({ children }) {
  logger.debug('children', children);

  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    const { returnTo = false } = appState;
    navigate(returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      audience={config.audience}
      scope="openid profile read:current_user update:current_user_metadata"
      redirectUri={`${window.location.origin}/callback`}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      app_env={config.REACT_APP_ENVIRON}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.element,
};
