/* eslint-disable */

export const sumBySource = /* GraphQL */ `
  query SumBySource(
    $clientID: String
    $startDate: String
    $endDate: String
    $includeExclusions: Boolean
    $separateRecurring: Boolean
  ) {
    sumBySource(
      clientID: $clientID
      startDate: $startDate
      endDate: $endDate
      includeExclusions: $includeExclusions
      separateRecurring: $separateRecurring
    )
  }
`;

export const databricksTableDataFetch = /* GraphQL */ `
  query databricksTableDataFetch(
    $sqlQuery: [String]
  ) {
    databricksTableDataFetch(
      sqlQuery: $sqlQuery
    )
  }
`;

export const databricksRunJob = /* GraphQL */ `
  query databricksRunJob(
    $jobId: String
    $clients: String
    $start: String
    $end: String
    $refresh_data: Boolean
    $breakdown: String
  ) {
    databricksRunJob(
      jobId: $jobId
      clients: $clients
      start:$start
      end: $end
      refresh_data: $refresh_data
      breakdown: $breakdown
    )
  }
`;

export const goopFetch = /* GraphQL */ `
  query GoopFetch(
    $goopId: Int
    $clientId: String
    $lastGoopTGPulled: String
    $rowId: String
  ) {
    goopFetch(
      goopId: $goopId
      clientId: $clientId
      lastGoopTGPulled: $lastGoopTGPulled
      rowId: $rowId
    )
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($client_id: ID!) {
    getClient(client_id: $client_id) {
      client_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_ids
      goop_client_ids
      totem_client_ids
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      advantageai_member_on_date
      strategy_partner_on_date
      ngp_everyaction_committee_id
      ngp_everyaction_committee_name
      ngp_actblue_manual_codes
      sms_platform
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_proportion_floor_value
      donor_floor_type
      multi_member_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $client_id: ID
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClients(
      client_id: $client_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        client_id
        client_full_name
        jira_project_name
        client_type
        is_active
        is_advantage_member
        is_strategy_partner
        is_mid_level_member
        exclude_from_match_qualifier
        featurize
        people_platform
        action_platform
        transaction_platform
        exhaust_platform
        curate_subscriber
        curate_frequency
        curate_days
        curate_delivery_auto_upload
        suppressed_domains
        actblue_entity_ids
        sfg_surveys_client_ids
        goop_client_ids
        totem_client_ids
        salsa_organization_key
        ngp_everyaction_database_name
        ngp_everyaction_server_name
        salesforce_location
        pardot_location
        hub_source_path
        facebook_account_ids
        facebook_locations
        google_account_ids
        bing_account_ids
        twitter_account_ids
        outbrain_account_ids
        code_dictionary_url
        annelewis_slack_channel
        timezone
        mailchimp_directory
        classy_org_id
        advantageai_member_on_date
        strategy_partner_on_date
        ngp_everyaction_committee_id
        ngp_everyaction_committee_name
        ngp_actblue_manual_codes
        sms_platform
        share_cap_max_shares
        share_cap_months
        hubspot_stitch_integration_name
        tatango_client_id
        inbox_monster_rule
        campaign_monitor_location
        pause_data
        donor_proportion_floor_value
        donor_floor_type
        multi_member_clients
        stw_subaccount_ids
        notes
        is_low_inventory
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimeStamps = /* GraphQL */ `
  query GetTimeStamps($id: ID!) {
    getTimeStamps(id: $id) {
      client_id
      column_name
      changed_to
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTimeStamps = /* GraphQL */ `
  query ListTimeStamps(
    $filter: ModelTimeStampsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeStamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        client_id
        column_name
        changed_to
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchTimeStamps = /* GraphQL */ `
  query SearchTimeStamps(
    $filter: SearchableTimeStampsFilterInput
    $sort: [SearchableTimeStampsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTimeStampsAggregationInput]
  ) {
    searchTimeStamps(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        client_id
        column_name
        changed_to
        id
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getRolePermissions = /* GraphQL */ `
  query GetRolePermissions($id: ID!) {
    getRolePermissions(id: $id) {
      role_id
      role_name
      client_permission_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const listRolePermissions = /* GraphQL */ `
  query ListRolePermissions(
    $filter: ModelRolePermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRolePermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        role_id
        role_name
        client_permission_id
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupPermissions = /* GraphQL */ `
  query GetGroupPermissions($id: ID!) {
    getGroupPermissions(id: $id) {
      group_id
      client_id
      id
      createdAt
      updatedAt
    }
  }
`;
export const listGroupPermissions = /* GraphQL */ `
  query ListGroupPermissions(
    $filter: ModelGroupPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group_id
        client_id
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupPermissionsByID = /* GraphQL */ `
  query GroupPermissionsByID(
    $group_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupPermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupPermissionsByID(
      group_id: $group_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        group_id
        client_id
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClientAttributePermissions = /* GraphQL */ `
  query GetClientAttributePermissions($id: ID!) {
    getClientAttributePermissions(id: $id) {
      client_permission_id
      client_full_name
      jira_project_name
      client_type
      is_active
      is_advantage_member
      is_strategy_partner
      is_mid_level_member
      exclude_from_match_qualifier
      featurize
      people_platform
      action_platform
      transaction_platform
      exhaust_platform
      curate_subscriber
      curate_frequency
      curate_days
      curate_delivery_auto_upload
      suppressed_domains
      actblue_entity_ids
      sfg_surveys_client_id
      goop_client_id
      salsa_organization_key
      ngp_everyaction_database_name
      ngp_everyaction_server_name
      salesforce_location
      pardot_location
      hub_source_path
      facebook_account_ids
      facebook_locations
      google_account_ids
      bing_account_ids
      twitter_account_ids
      outbrain_account_ids
      code_dictionary_url
      annelewis_slack_channel
      timezone
      mailchimp_directory
      classy_org_id
      advantageai_member_on_date
      strategy_partner_on_date
      ngp_everyaction_committee_id
      ngp_everyaction_committee_name
      ngp_actblue_manual_codes
      sms_platform
      share_cap_max_shares
      share_cap_months
      hubspot_stitch_integration_name
      tatango_client_id
      inbox_monster_rule
      campaign_monitor_location
      pause_data
      donor_proportion_floor_value
      donor_floor_type
      multi_member_clients
      stw_subaccount_ids
      notes
      is_low_inventory
      id
      createdAt
      updatedAt
    }
  }
`;
export const listClientAttributePermissions = /* GraphQL */ `
  query ListClientAttributePermissions(
    $filter: ModelClientAttributePermissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientAttributePermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        client_permission_id
        client_full_name
        jira_project_name
        client_type
        is_active
        is_advantage_member
        is_strategy_partner
        is_mid_level_member
        exclude_from_match_qualifier
        featurize
        people_platform
        action_platform
        transaction_platform
        exhaust_platform
        curate_subscriber
        curate_frequency
        curate_days
        curate_delivery_auto_upload
        suppressed_domains
        actblue_entity_ids
        sfg_surveys_client_id
        goop_client_id
        salsa_organization_key
        ngp_everyaction_database_name
        ngp_everyaction_server_name
        salesforce_location
        pardot_location
        hub_source_path
        facebook_account_ids
        facebook_locations
        google_account_ids
        bing_account_ids
        twitter_account_ids
        outbrain_account_ids
        code_dictionary_url
        annelewis_slack_channel
        timezone
        mailchimp_directory
        classy_org_id
        advantageai_member_on_date
        strategy_partner_on_date
        ngp_everyaction_committee_id
        ngp_everyaction_committee_name
        ngp_actblue_manual_codes
        sms_platform
        share_cap_max_shares
        share_cap_months
        hubspot_stitch_integration_name
        tatango_client_id
        inbox_monster_rule
        campaign_monitor_location
        pause_data
        donor_proportion_floor_value
        donor_floor_type
        multi_member_clients
        stw_subaccount_ids
        notes
        is_low_inventory
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      firstname
      lastname
      email
      group_id
      role_id
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        group_id
        role_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByID = /* GraphQL */ `
  query UserByID(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByID(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        email
        group_id
        role_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSources = /* GraphQL */ `
  query GetSources($id: ID!) {
    getSources(id: $id) {
      id
      createdAt
      client_id
      channel_category
      channel
      source
      from_goop
      codes {
        items {
          id
          createdAt
          client_id
          code
          source
          fixed_cost_media_spend
          fixed_cost_cpa_override
          revenue_category
          audience
          audience_category
          type
          platforms_with_spend
          total_spend
          most_recent_spend
          most_recent_spend_date
          most_recent_transaction_date
          revenue
          transactions
          acquisitions
          most_recent_acquisition_date
          from_goop
          updatedAt
          page
          actblue_managing_entities
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listSources = /* GraphQL */ `
  query ListSources(
    $id: ID
    $filter: ModelSourcesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSources(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        client_id
        channel_category
        channel
        source
        from_goop
        codes {
          items {
            id
            createdAt
            client_id
            code
            source
            fixed_cost_media_spend
            fixed_cost_cpa_override
            revenue_category
            audience
            audience_category
            type
            platforms_with_spend
            total_spend
            most_recent_spend
            most_recent_spend_date
            most_recent_transaction_date
            revenue
            transactions
            acquisitions
            most_recent_acquisition_date
            from_goop
            updatedAt
            page
            actblue_managing_entities
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const sourceByClientID = /* GraphQL */ `
  query SourceByClientID(
    $client_id: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sourceByClientID(
      client_id: $client_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        client_id
        channel_category
        channel
        source
        from_goop
        codes {
          items {
            id
            createdAt
            client_id
            code
            source
            fixed_cost_media_spend
            fixed_cost_cpa_override
            revenue_category
            audience
            audience_category
            type
            platforms_with_spend
            total_spend
            most_recent_spend
            most_recent_spend_date
            most_recent_transaction_date
            revenue
            transactions
            acquisitions
            most_recent_acquisition_date
            from_goop
            updatedAt
            page
            actblue_managing_entities
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const sourceBySource = /* GraphQL */ `
  query SourceBySource(
    $source: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sourceBySource(
      source: $source
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        client_id
        channel_category
        channel
        source
        from_goop
        codes {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSources = /* GraphQL */ `
  query SearchSources(
    $filter: SearchableSourcesFilterInput
    $sort: [SearchableSourcesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSourcesAggregationInput]
  ) {
    searchSources(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        client_id
        channel_category
        channel
        source
        from_goop
        codes {
          items {
            id
            createdAt
            client_id
            code
            source
            fixed_cost_media_spend
            fixed_cost_cpa_override
            revenue_category
            audience
            audience_category
            type
            platforms_with_spend
            total_spend
            most_recent_spend
            most_recent_spend_date
            most_recent_transaction_date
            revenue
            transactions
            acquisitions
            most_recent_acquisition_date
            from_goop
            updatedAt
            page
            actblue_managing_entities
          }
          nextToken
        }
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCodes = /* GraphQL */ `
  query GetCodes($id: ID!) {
    getCodes(id: $id) {
      id
      createdAt
      client_id
      code
      source
      fixed_cost_media_spend
      fixed_cost_cpa_override
      revenue_category
      audience
      audience_category
      type
      platforms_with_spend
      total_spend
      most_recent_spend
      most_recent_spend_date
      most_recent_transaction_date
      revenue
      transactions
      acquisitions
      most_recent_acquisition_date
      from_goop
      updatedAt
      page
      actblue_managing_entities
    }
  }
`;
export const listCodes = /* GraphQL */ `
  query ListCodes(
    $id: ID
    $filter: ModelCodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        client_id
        code
        source
        fixed_cost_media_spend
        fixed_cost_cpa_override
        revenue_category
        audience
        audience_category
        type
        platforms_with_spend
        total_spend
        most_recent_spend
        most_recent_spend_date
        most_recent_transaction_date
        revenue
        transactions
        acquisitions
        most_recent_acquisition_date
        from_goop
        updatedAt
        page
        actblue_managing_entities
      }
      nextToken
    }
  }
`;
export const codesByClientID = /* GraphQL */ `
  query CodesByClientID(
    $client_id: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    codesByClientID(
      client_id: $client_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        client_id
        code
        source
        fixed_cost_media_spend
        fixed_cost_cpa_override
        revenue_category
        audience
        audience_category
        type
        platforms_with_spend
        total_spend
        most_recent_spend
        most_recent_spend_date
        most_recent_transaction_date
        revenue
        transactions
        acquisitions
        most_recent_acquisition_date
        from_goop
        updatedAt
        page
        actblue_managing_entities
      }
      nextToken
    }
  }
`;
export const codesByCode = /* GraphQL */ `
  query CodesByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    codesByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        client_id
        code
        source
        fixed_cost_media_spend
        fixed_cost_cpa_override
        revenue_category
        audience
        audience_category
        type
        platforms_with_spend
        total_spend
        most_recent_spend
        most_recent_spend_date
        most_recent_transaction_date
        revenue
        transactions
        acquisitions
        most_recent_acquisition_date
        from_goop
        updatedAt
        page
        actblue_managing_entities
      }
      nextToken
    }
  }
`;
export const codesBySource = /* GraphQL */ `
  query CodesBySource(
    $source: String!
    $code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    codesBySource(
      source: $source
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        client_id
        code
        source
        fixed_cost_media_spend
        fixed_cost_cpa_override
        revenue_category
        audience
        audience_category
        type
        platforms_with_spend
        total_spend
        most_recent_spend
        most_recent_spend_date
        most_recent_transaction_date
        revenue
        transactions
        acquisitions
        most_recent_acquisition_date
        from_goop
        updatedAt
        page
        actblue_managing_entities
      }
      nextToken
    }
  }
`;
export const searchCodes = /* GraphQL */ `
  query SearchCodes(
    $filter: SearchableCodesFilterInput
    $sort: [SearchableCodesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCodesAggregationInput]
  ) {
    searchCodes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        client_id
        code
        source
        fixed_cost_media_spend
        fixed_cost_cpa_override
        revenue_category
        audience
        audience_category
        type
        platforms_with_spend
        total_spend
        most_recent_spend
        most_recent_spend_date
        most_recent_transaction_date
        revenue
        transactions
        acquisitions
        most_recent_acquisition_date
        from_goop
        updatedAt
        page
        actblue_managing_entities
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getTransactions = /* GraphQL */ `
  query GetTransactions($id: ID!) {
    getTransactions(id: $id) {
      id
      client_id
      email
      code
      code_source {
        items {
          source
          }
      }
      amount
      transaction_dt
      recurring_period
      recurrence
      recurring_type
      refund_flag
      exclude_flag
      exclusion_updated_dt
      foreign_platform
      foreign_client_key
      foreign_transaction_key
      mobile_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $id: ID
    $filter: ModelTransactionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTransactions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        client_id
        email
        code
        code_source {
          items {
            source
          }
        }
        amount
        transaction_dt
        recurring_period
        recurrence
        recurring_type
        refund_flag
        exclude_flag
        exclusion_updated_dt
        foreign_platform
        foreign_client_key
        foreign_transaction_key
        mobile_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByClientID = /* GraphQL */ `
  query TransactionsByClientID(
    $client_id: String!
    $transaction_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByClientID(
      client_id: $client_id
      transaction_dt: $transaction_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        email
        code
        code_source {
          items {
            source
          }
        }
        amount
        transaction_dt
        recurring_period
        recurrence
        recurring_type
        refund_flag
        exclude_flag
        exclusion_updated_dt
        foreign_platform
        foreign_client_key
        foreign_transaction_key
        mobile_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transByCodeAndDate = /* GraphQL */ `
  query TransByCodeAndDate(
    $code: String!
    $transaction_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transByCodeAndDate(
      code: $code
      transaction_dt: $transaction_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_id
        email
        code
        code_source {
          items {
            source
          }
        }
        amount
        transaction_dt
        recurring_period
        recurrence
        recurring_type
        refund_flag
        exclude_flag
        exclusion_updated_dt
        foreign_platform
        foreign_client_key
        foreign_transaction_key
        mobile_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchTransactions = /* GraphQL */ `
  query SearchTransactions(
    $filter: SearchableTransactionsFilterInput
    $sort: [SearchableTransactionsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTransactionsAggregationInput]
  ) {
    searchTransactions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        client_id
        email
        code
        code_source {
          items {
            source
          }
        }
        amount
        transaction_dt
        recurring_period
        recurrence
        recurring_type
        refund_flag
        exclude_flag
        exclusion_updated_dt
        foreign_platform
        foreign_client_key
        foreign_transaction_key
        mobile_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInsightsAcqDeliveries = /* GraphQL */ `
  query GetInsightsAcqDeliveries($id: ID!) {
    getInsightsAcqDeliveries(id: $id) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listInsightsAcqDeliveries = /* GraphQL */ `
  query ListInsightsAcqDeliveries(
    $id: ID
    $filter: ModelInsightsAcqDeliveriesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsightsAcqDeliveries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const acqDeliveriesResultsByClient = /* GraphQL */ `
  query AcqDeliveriesResultsByClient(
    $client: String!
    $updated_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsAcqDeliveriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acqDeliveriesResultsByClient(
      client: $client
      updated_dt: $updated_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInsightsAcqDeliveries = /* GraphQL */ `
  query SearchInsightsAcqDeliveries(
    $filter: SearchableInsightsAcqDeliveriesFilterInput
    $sort: [SearchableInsightsAcqDeliveriesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsightsAcqDeliveriesAggregationInput]
  ) {
    searchInsightsAcqDeliveries(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInsightsAcqDeliveriesCohort = /* GraphQL */ `
  query GetInsightsAcqDeliveriesCohort($id: ID!) {
    getInsightsAcqDeliveriesCohort(id: $id) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listInsightsAcqDeliveriesCohorts = /* GraphQL */ `
  query ListInsightsAcqDeliveriesCohorts(
    $id: ID
    $filter: ModelInsightsAcqDeliveriesCohortFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsightsAcqDeliveriesCohorts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const acqDeliveriesResultsCohortByClient = /* GraphQL */ `
  query AcqDeliveriesResultsCohortByClient(
    $client: String!
    $updated_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsAcqDeliveriesCohortFilterInput
    $limit: Int
    $nextToken: String
  ) {
    acqDeliveriesResultsCohortByClient(
      client: $client
      updated_dt: $updated_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInsightsAcqDeliveriesCohorts = /* GraphQL */ `
  query SearchInsightsAcqDeliveriesCohorts(
    $filter: SearchableInsightsAcqDeliveriesCohortFilterInput
    $sort: [SearchableInsightsAcqDeliveriesCohortSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsightsAcqDeliveriesCohortAggregationInput]
  ) {
    searchInsightsAcqDeliveriesCohorts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInsightsAdvDeliveries = /* GraphQL */ `
  query GetInsightsAdvDeliveries($id: ID!) {
    getInsightsAdvDeliveries(id: $id) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listInsightsAdvDeliveries = /* GraphQL */ `
  query ListInsightsAdvDeliveries(
    $id: ID
    $filter: ModelInsightsAdvDeliveriesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsightsAdvDeliveries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const advDeliveriesResultsByClient = /* GraphQL */ `
  query AdvDeliveriesResultsByClient(
    $client: String!
    $updated_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsAdvDeliveriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advDeliveriesResultsByClient(
      client: $client
      updated_dt: $updated_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInsightsAdvDeliveries = /* GraphQL */ `
  query SearchInsightsAdvDeliveries(
    $filter: SearchableInsightsAdvDeliveriesFilterInput
    $sort: [SearchableInsightsAdvDeliveriesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsightsAdvDeliveriesAggregationInput]
  ) {
    searchInsightsAdvDeliveries(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInsightsAdvDeliveriesCohort = /* GraphQL */ `
  query GetInsightsAdvDeliveriesCohort($id: ID!) {
    getInsightsAdvDeliveriesCohort(id: $id) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listInsightsAdvDeliveriesCohorts = /* GraphQL */ `
  query ListInsightsAdvDeliveriesCohorts(
    $id: ID
    $filter: ModelInsightsAdvDeliveriesCohortFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsightsAdvDeliveriesCohorts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const advDeliveriesResultsCohortByClient = /* GraphQL */ `
  query AdvDeliveriesResultsCohortByClient(
    $client: String!
    $updated_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsAdvDeliveriesCohortFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advDeliveriesResultsCohortByClient(
      client: $client
      updated_dt: $updated_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInsightsAdvDeliveriesCohorts = /* GraphQL */ `
  query SearchInsightsAdvDeliveriesCohorts(
    $filter: SearchableInsightsAdvDeliveriesCohortFilterInput
    $sort: [SearchableInsightsAdvDeliveriesCohortSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsightsAdvDeliveriesCohortAggregationInput]
  ) {
    searchInsightsAdvDeliveriesCohorts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInsightsReacDeliveries = /* GraphQL */ `
  query GetInsightsReacDeliveries($id: ID!) {
    getInsightsReacDeliveries(id: $id) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listInsightsReacDeliveries = /* GraphQL */ `
  query ListInsightsReacDeliveries(
    $id: ID
    $filter: ModelInsightsReacDeliveriesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsightsReacDeliveries(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reacDeliveriesResultsByClient = /* GraphQL */ `
  query ReacDeliveriesResultsByClient(
    $client: String!
    $updated_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsReacDeliveriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reacDeliveriesResultsByClient(
      client: $client
      updated_dt: $updated_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInsightsReacDeliveries = /* GraphQL */ `
  query SearchInsightsReacDeliveries(
    $filter: SearchableInsightsReacDeliveriesFilterInput
    $sort: [SearchableInsightsReacDeliveriesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsightsReacDeliveriesAggregationInput]
  ) {
    searchInsightsReacDeliveries(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInsightsReacDeliveriesCohort = /* GraphQL */ `
  query GetInsightsReacDeliveriesCohort($id: ID!) {
    getInsightsReacDeliveriesCohort(id: $id) {
      id
      Break_even_date
      Days_to_break_even
      Projected_break_even_date
      Projected_days_to_break_even
      ROAS
      ROAS_Over_Days
      Unsubs
      average_gift
      category
      click_emails
      click_rate
      clicks
      client
      client_type
      cohort
      cohort_name
      contributions
      contributions_over_sends
      donors
      emails_acquired
      emails_messaged
      first_delivery_date
      first_uploaded_date
      gifts_over_opens
      hard_bounce_emails
      hard_bounce_rate
      hard_bounces
      jira_ticket_num
      open_emails
      open_rate
      opens
      optout_emails
      optout_rate
      optouts
      pixel_load_emails
      pixel_load_rate
      pixel_loads
      revenue
      sends
      soft_bounce_emails
      soft_bounce_rate
      soft_bounces
      spam_emails
      spam_rate
      spams
      spend
      delivery_month
      delivery_year
      cost_per_donor
      send_emails_past_30
      recurring_flag
      updated_dt
      createdAt
      updatedAt
    }
  }
`;
export const listInsightsReacDeliveriesCohorts = /* GraphQL */ `
  query ListInsightsReacDeliveriesCohorts(
    $id: ID
    $filter: ModelInsightsReacDeliveriesCohortFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInsightsReacDeliveriesCohorts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        recurring_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reacDeliveriesResultsCohortByClient = /* GraphQL */ `
  query ReacDeliveriesResultsCohortByClient(
    $client: String!
    $updated_dt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsReacDeliveriesCohortFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reacDeliveriesResultsCohortByClient(
      client: $client
      updated_dt: $updated_dt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        recurring_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchInsightsReacDeliveriesCohorts = /* GraphQL */ `
  query SearchInsightsReacDeliveriesCohorts(
    $filter: SearchableInsightsReacDeliveriesCohortFilterInput
    $sort: [SearchableInsightsReacDeliveriesCohortSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInsightsReacDeliveriesCohortAggregationInput]
  ) {
    searchInsightsReacDeliveriesCohorts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Break_even_date
        Days_to_break_even
        Projected_break_even_date
        Projected_days_to_break_even
        ROAS
        ROAS_Over_Days
        Unsubs
        average_gift
        category
        click_emails
        click_rate
        clicks
        client
        client_type
        cohort
        cohort_name
        contributions
        contributions_over_sends
        donors
        emails_acquired
        emails_messaged
        first_delivery_date
        first_uploaded_date
        gifts_over_opens
        hard_bounce_emails
        hard_bounce_rate
        hard_bounces
        jira_ticket_num
        open_emails
        open_rate
        opens
        optout_emails
        optout_rate
        optouts
        pixel_load_emails
        pixel_load_rate
        pixel_loads
        revenue
        sends
        soft_bounce_emails
        soft_bounce_rate
        soft_bounces
        spam_emails
        spam_rate
        spams
        spend
        delivery_month
        delivery_year
        cost_per_donor
        send_emails_past_30
        recurring_flag
        updated_dt
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const batchEditCodes = /* GraphQL */ `
  query BatchEditCodes($input: String) {
    batchEditCodes(
      input: $input
    )
  }
`;

export const getTokenTC = /* GraphQL */ `
  query GetTokenTC($input: String) {
    getTokenTC(
      input: $input
    )
  }
`;

export const importHistoryByClient = /* GraphQL */ `
  query ImportHistoryByClient(
    $client: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImportHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    importHistoryByClient(
      client: $client
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        s3_key
        client
        csv_name
        timestamp
        error
        warning
        updated_exclusion_count
        total_req_exclusion_count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const exportTrigger = /* GraphQL */ `
  query ExportTrigger($input: String) {
    exportTrigger(
      input: $input
    )
  }
`;

export const getDatalakeHistory = /* GraphQL */ `
  query GetDatalakeHistory($sync_name: ID!) {
    getDatalakeHistory(sync_name: $sync_name) {
      sync_name
      last_updated_version
      last_updated_startTime
      last_updated_endTime
    }
  }
`;
